import parse from "html-react-parser";
import Loader from "../../Component/UI/Loader";
import { useStaticPageData } from "../../Middleware/useStaticPageData";
// import useFetchMetaTags from "../../Middleware/useFetchMetaTags";
// import { Helmet } from "react-helmet-async";

export default function TermsCondition() {
  const {
    data: terms,
    isLoading,
    error,
  } = useStaticPageData("terms-and-conditions");
  // const { data: tags } = useFetchMetaTags("terms-and-conditions");

  const content =
    typeof terms?.data?.terms_and_conditions_content === "string"
      ? terms?.data?.terms_and_conditions_content
      : "";

  return (
    <>
      {/* <Helmet>
        <title>{`${tags?.data?.title}`}</title>
        <meta name="description" content={`${tags?.data?.description}`} />
        <link rel="canonical" href={`${tags?.data?.canonical}`} />
        <meta property="og:locale" content={`${tags?.data?.og_locale}`} />
        <meta property="og:type" content={`${tags?.data?.og_type}`} />
        <meta property="og:title" content={`${tags?.data?.og_title}`} />
        <meta
          property="og:description"
          content={`${tags?.data?.og_description}`}
        />
        <meta
          property="og:image"
          content={`https://admin.asiantrader.biz/wp-content/uploads/sites/1/2024/09/AT-logo-White-3-1.png`}
        />
        <meta property="og:url" content={`${tags?.data?.og_url}`} />
        <meta property="og:site_name" content={`${tags?.data?.og_site_name}`} />
        <meta
          property="og:updated_time"
          content={`${tags?.data?.og_updated_time}`}
        />
        <meta
          property="article:published_time"
          content={`${tags?.data?.article_published_time}`}
        />
        <meta
          property="article:modified_time"
          content={`${tags?.data?.article_modified_time}`}
        />
        <meta name="twitter:card" content={`${tags?.data?.twitter_card}`} />
        <meta name="twitter:title" content={`${tags?.data?.twitter_title}`} />
        <meta
          name="twitter:description"
          content={`${tags?.data?.twitter_description}`}
        />
      </Helmet> */}
      <section className="pb-16 bg-blur">
        <div className="container mx-auto px-7">
          <div className="px-3 border-b border-greyb pb-2 mb-10">
            <div>
              <h1 className="text-dblack text-2xl font-bold">
                Terms & Conditions
              </h1>
            </div>
          </div>

          <div className="sm:pl-3 sm:pr-12 pr-3 pl-3">
            {isLoading ? (
              <Loader />
            ) : error ? (
              <div>1</div>
            ) : (
              <div className="td-fix-index terms">{parse(content)}</div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
